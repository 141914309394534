
import {doc, getDoc, addDoc, collection, runTransaction} from "firebase/firestore";
import {db} from "../../../index";
import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from 'react-router-dom';
import globalMasehtot from "../../../masehtot.json"
import Spinner from "../../../spinner";
import Cookies from 'js-cookie';

const AddUserD = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [privateSelection, setPrivateSelection] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [phone, setPhone] = useState('');
    const [sharingUser, setSharingUser] = React.useState([]);
    const [newUser, setNewUser] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        masehtotValidation().then(siumObj => {
            if (siumObj) {
                const newUserObj = {
                    email: event.target.email.value,
                    phone: phone,
                    fullName: event.target.fullName.value,
                    nishmat: event.target.nishmat.value,
                    siumId: params.siumId,
                    lastModified: new Date(),
                    pages: privateSelection.reduce((acc, item) => {
                        acc[item] = false;
                        return acc;
                    }, {}),
                    pagesNum: privateSelection.length
                }
                if (sium.shemMehina) {
                    newUserObj.mehinaName = event.target.mehinaName.value;
                }
                setNewUser(newUserObj);
                setPrivateSelection([]);
                addDoc(collection(db, "users"), newUserObj).then((docRef) => {
                    if (isAdmin) {
                        setSharingUser(docRef);
                        var myModal = document.getElementById('sharingModal');
                        var modal = window.bootstrap.Modal.getOrCreateInstance(myModal);
                        modal.show();
                    } else {
                        navigate("/updateStatusDapim/" + docRef.id);
                    }
                });
            } else {
                if (!errorMessage) {
                    displayErrorMessage(true, "ישנו כעת עומס בבחירת המסכתות, נסה בעוד מספר שניות");
                }
                setIsSubmitting(false);
            }
        })
    };

    function displayErrorMessage(reset, errorMsg) {
        if (reset) {
            setPrivateSelection([]);
        }
        setErrorMessage(errorMsg);
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    async function masehtotValidation() {
        const docRef = doc(db, "siumim", params.siumId);
        setErrorMessage("");

        try {
            return await runTransaction(db, async (transaction) => {
                const sium = await transaction.get(docRef);
                if (sium.exists()) {
                    const siumObj = sium.data();
                    setSium(siumObj);
                    const siumSelection = siumObj.masehtotSelection;
                    let validationResult = true;
                    if (privateSelection.length === 0) {
                        validationResult = false;
                        displayErrorMessage(true, "לא נבחרו מסכתות");
                        return null;
                    }
                    for (const i in privateSelection.sort()) {
                        const selection = privateSelection[i].split("-");
                        const masehetKey = selection[0];
                        const pageNum = parseInt(selection[1]);
                        const index = siumSelection.findIndex(obj => Object.keys(obj)[0] === masehetKey);
                        if (!selection[0] || !selection[1] || siumSelection[index][masehetKey][pageNum]) {
                            validationResult = false;
                            displayErrorMessage(true, "כנראה שבחרת מסכתות שנתפסו, נסה לבחור שוב");
                            return null;
                        }
                        siumSelection[index][masehetKey][pageNum] = true;
                    }
                    if (validationResult) {
                        siumObj.masehtotSelection = siumSelection;
                        siumObj.lastAccessed = new Date().getTime();
                        transaction.update(docRef, siumObj);
                        return siumObj;
                    }
                    return null;
                } else {
                    displayErrorMessage(true, "נראה שסיום השס הנוכחי לא קיים");
                    return null;
                }
            });
        } catch (e) {
            displayErrorMessage(false, "ישנו כעת עומס בבחירת המסכתות, נסה בעוד מספר שניות")
            console.log("Transaction failed: ", e);
            return null;
        }
    }

    function handleWholeMasehet(m_key, isWholeMasehet) {
        if (isWholeMasehet) {
            const newPrivateSelection = privateSelection.filter((p) => !p.startsWith(m_key));
            setPrivateSelection(newPrivateSelection);
        } else {
            const newPrivateSelection = [...privateSelection];
            sium.masehtotSelection.filter(obj => Object.keys(obj)[0] === m_key).map((masehetSelection, m_i) => {
                for (let i = 0; i < masehetSelection[m_key].length; i++) {
                    const pageVal = m_key + "-" + i;
                    if (!masehetSelection[m_key][i] && !privateSelection.includes(pageVal)) {
                        newPrivateSelection.push(pageVal);
                    }
                }
                return;
            });
            setPrivateSelection(newPrivateSelection);
        }
    }

    function handlePageSelection(m_key, p_i) {
        const pageVal = m_key + "-" + p_i;
        let newPrivateSelectionC = [];
        if (privateSelection.includes(pageVal)) {
            newPrivateSelectionC = privateSelection.filter((p) => p !== pageVal);
        } else {
            newPrivateSelectionC = [...privateSelection, pageVal];
        }
        setPrivateSelection(newPrivateSelectionC);
    }

    function sharePage() {
        if (navigator.share) {
            if (sium.displayId) {
                navigator.share({
                    title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                    text: 'מצב הש"ס של "' + sium.kehilaName + '"',
                    url: '/displaySiumDapim/' + sium.displayId
                });
            } else {
                navigator.share({
                    title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                    text: 'הוספת לומד חדש',
                    url: '/addUser/' + params.siumId
                });
            }
        }
    }

    function generalSharing() {
        if (navigator.share) {
            navigator.share({
                title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                text: sharingUser.fullName,
                url: '/updateStatusDapim/' + sharingUser.id
            });
        }
    }

    function whatsappSharing() {
        if (/^[0-9-]+$/.test(newUser.phone)) {
            const whatsappLink = 'https://wa.me/972' + newUser.phone.replace('-', '') + '?text=' + encodeURIComponent('https://siumshas.co.il/updateStatusDapim/' + sharingUser.id);
            window.open(whatsappLink, '_blank');
        } else {
            return;
        }
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
    };

    useEffect(() => {
        const docRef = doc(db, "siumim", params.siumId);
        getDoc(docRef).then(sium => {
            if (sium) {
                const siumObj = sium.data();
                const siumShasId = Cookies.get('siumShasId');
                if (siumShasId && siumShasId === sium.id) {
                    if (siumObj.redirectNiul) {
                        const siumPhone = Cookies.get('siumPhone');
                        if (siumPhone) {
                            setIsAdmin(true);
                        }
                    } else {
                        setIsAdmin(true);
                    }
                }
                setSium(siumObj);
            }
        });

    },[params.siumId]);

    if (sium && sium.kehilaName && globalMasehtot.allMasehtot) {
        const pagesNames = globalMasehtot.onlyPages;
        const masehtotList = sium.masehtotSelection
            .sort((a, b) => globalMasehtot.allMasehtot[Object.keys(a)[0]].index - globalMasehtot.allMasehtot[Object.keys(b)[0]].index)
            .map((siumMasehetSelection, m_i) => {
            const masehetKey = Object.keys(siumMasehetSelection)[0];
            const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
            let masehetPagesTakenCounter = 0;
            let masehetPagesCounter = siumMasehetSelection[masehetKey].length;
            const startIndex = !currentMasehet.canByChoose ? 0 :
                globalMasehtot.onlyPages.indexOf((currentMasehet.chapters[0].startPage).replace(/[.:]/g, ""))

            const pagesForMasehet = siumMasehetSelection[masehetKey].map((pageSelected, p_index) => {
                const pageName = currentMasehet.isMishna ? globalMasehtot.allChapters[p_index] : pagesNames[startIndex + p_index];
                const wasTaken = pageSelected;
                const pageId = `${masehetKey}-${p_index}`;

                if (wasTaken) {
                    masehetPagesTakenCounter++;
                }

                return <>
                    {!currentMasehet.isMishna && <button type="button" id={pageId} disabled={wasTaken}
                            className={`btn daf-button ${privateSelection.includes(pageId) ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => handlePageSelection(masehetKey, p_index)}>{pageName}</button>}
                    {currentMasehet.isMishna && <button type="button" id={pageId} disabled={wasTaken}
                            className={`btn mishna-button ${privateSelection.includes(pageId) ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => handlePageSelection(masehetKey, p_index)}>{pageName}</button>}
                </>
            })

            masehetPagesTakenCounter += privateSelection.filter(pageId => pageId.startsWith(masehetKey)).length;
            const displayWholeMasehet = masehetPagesTakenCounter < masehetPagesCounter || privateSelection.some((p) => p.startsWith(masehetKey));

            return <div className="accordion-item" key={masehetKey + "-masehet"}>
                <h3 className="accordion-header">
                    <button className={`accordion-button collapsed ${privateSelection.some((p) => p.startsWith(masehetKey)) ? 'selected-masehet-color' : ''}`} type="button" data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + masehetKey} aria-expanded="false" aria-controls={"collapse" + masehetKey}>
                        <span className="masehet-title">{currentMasehet.name}</span>
                        {currentMasehet.isMishna && <span className="badge bg-success margin-right-10">משנה</span>}
                        {masehetPagesCounter === masehetPagesTakenCounter && <span className="badge bg-info margin-right-10">המסכת נתפסה</span>}
                        {masehetPagesTakenCounter < masehetPagesCounter && <span className="badge bg-primary margin-right-10">{masehetPagesCounter} / {masehetPagesTakenCounter} נתפסו</span>}
                    </button>
                </h3>
                <div id={"collapse" + masehetKey} className="accordion-collapse collapse" data-bs-parent="#masehtotList">
                    <div className="accordion-body">
                        {displayWholeMasehet && <div className="form-check form-switch finish-all-chapter right-text">
                            <input className="form-check-input r_float" type="checkbox"
                                   id={"all-" + masehetKey} checked={masehetPagesCounter === masehetPagesTakenCounter}
                                   onClick={() => handleWholeMasehet(masehetKey, masehetPagesCounter === masehetPagesTakenCounter)} />
                            <label className="form-check-label" htmlFor={"all-" + masehetKey}>לסמן את כל המסכת</label>
                        </div>}
                        <div id={masehetKey + "Chapters"}>
                            {pagesForMasehet}
                        </div>
                    </div>

                </div>
            </div>
        });
        return (
            <div className="sium-main" key='addUserPage'>
                <div className="sium-main-container">
                    <div className="basad-with-return">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                        {isAdmin && <div className="return-container">
                            <a className="btn sium-button return-button"
                               href={"/niulSium/" + params.siumId + "/siumStatus"}>חזור</a>
                        </div>}
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/"} aria-label="Main page"></a>
                    </div>
                    <div
                        className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                        <h2>סיום הש"ס של {sium.kehilaName}</h2>
                        <h4>הוספת לומד חדש</h4>
                        {sium.logo && <div alt="logo" className={sium.logo}/>}
                    </div>
                    {isAdmin && <div className="gap-2 col-8 mx-auto sharing-tip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="2.66666in" height="2.66666in" version="1.1"
                             viewBox="0 0 2667 2667" className="col-1 gap-2">
                            <g id="Layer_x0020_1">
                                <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                                <circle className="fil0" cx="1333" cy="1333" r="1333" id="id_101"></circle>
                                <path className="fil1"
                                      d="M1140 2086l-263 0c-13,0 -23,-10 -23,-23 0,-13 10,-23 23,-23l263 0c12,0 22,10 22,23 0,13 -10,23 -22,23zm-498 -1349c-8,0 -16,-4 -20,-12l-57 -98c-6,-10 -2,-24 9,-31 11,-6 25,-2 31,9l56 98c7,10 3,24 -8,31 -4,2 -7,3 -11,3zm176 -74c-10,0 -19,-6 -22,-16l-29 -109c-3,-13 4,-25 16,-28 12,-3 25,4 28,16l29 109c3,12 -4,24 -16,28 -2,0 -4,0 -6,0zm190 -24c-13,0 -23,-11 -23,-23l0 -113c0,-13 10,-23 23,-23 12,0 23,10 23,23l0 113c0,12 -11,23 -23,23zm356 693c-3,0 -7,-1 -10,-2 -8,-4 -13,-12 -13,-21l1 -167 -46 0c-12,0 -23,-11 -23,-23l0 -560c0,-13 11,-23 23,-23l820 0c13,0 23,10 23,23l0 560c0,12 -10,23 -23,23l-502 0 -236 185c-4,3 -9,5 -14,5zm633 -366c7,0 12,-6 12,-13 0,-7 -5,-13 -12,-13l-581 0c-7,0 -13,6 -13,13 0,7 6,13 13,13l581 0zm0 -124c7,0 12,-6 12,-13 0,-7 -5,-12 -12,-12l-581 0c-7,0 -13,5 -13,12 0,7 6,13 13,13l581 0zm-75 -123c7,0 12,-6 12,-13 0,-7 -5,-13 -12,-13l-431 0c-7,0 -13,6 -13,13 0,7 6,13 13,13l431 0zm-733 1259l-361 0c-13,0 -23,-10 -23,-23 0,-358 -83,-424 -157,-483 -62,-49 -120,-95 -120,-273 0,-265 215,-481 480,-481 84,0 167,23 239,64l0 337c0,27 22,49 49,49l19 0 0 141c0,19 10,36 27,45 7,3 14,5 22,5 11,0 22,-4 30,-11l90 -70c-15,115 -64,154 -116,194 -73,59 -157,125 -157,483 0,13 -10,23 -22,23zm-549 -779c0,61 2,121 52,165 10,9 21,17 32,25 11,10 23,19 34,29 33,30 60,64 82,103 2,4 6,6 11,6 2,0 4,0 6,-1 6,-3 8,-11 5,-17 -23,-42 -52,-78 -87,-110 -11,-10 -23,-20 -36,-30 -10,-8 -20,-16 -30,-24 -43,-38 -43,-93 -43,-146 0,-189 153,-343 342,-343 63,0 125,18 179,51 6,3 14,1 18,-5 3,-6 1,-13 -4,-17 -58,-36 -125,-54 -193,-54 -203,0 -368,165 -368,368zm431 988l-126 0c-12,0 -22,-11 -22,-23 0,-13 10,-23 22,-23l126 0c12,0 23,10 23,23 0,12 -10,23 -23,23z"
                                      id="id_102"></path>
                            </g>
                        </svg>
                        <span>שתף עם החבר'ה שיוסיפו את עצמם</span>
                        <button className="btn" type="button" onClick={() => sharePage()}>שתף</button>
                    </div>}
                    <form onSubmit={handleSubmit}>
                        <input className="form-control" type="text" placeholder="שם מלא" name="fullName"
                               aria-label="Full name" required={true}/>
                        <input className="form-control" type="text" placeholder="פלאפון" name="phone" onChange={handlePhoneChange} value={phone}
                               aria-label="Phone" required={true}/>
                        <input className="form-control" type="text" placeholder="אימייל" name="email"
                               aria-label="Email"/>
                        {sium.shemMehina && <input className="form-control" type="text" placeholder="שם המכינה" name="mehinaName"
                                aria-label="Mehina name" required={true}/>}
                        <input className="form-control" type="text" placeholder="לעילוי נשמת/לרפואת/להצלחת"
                               name="nishmat" aria-label="Nishmat"/>
                        <div className="accordion" id="masehtotList">
                            {masehtotList}
                        </div>
                        <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                            <div id="errorModal" className="toast" role="alert" aria-live="assertive"
                                 aria-atomic="true">
                                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
                                        aria-label="Close"></button>
                                <div className="toast-body">
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                        {privateSelection.length > 0 && !isSubmitting && <div className="sium-button-container sticky-bottom">
                            <button className="btn sium-button" type="submit">הוסף</button>
                        </div>}
                        {isSubmitting && <div className="sium-button-container sticky-bottom">
                            <button type="button" className="btn sium-button">
                                <div className="updateStatusSpinner"></div>
                            </button>
                        </div>}
                    </form>
                    <div className="modal fade modal-dialog-scrollable" id="sharingModal" data-bs-backdrop="static"
                         data-bs-keyboard="false"
                         tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        {sharingUser && newUser && <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{newUser.fullName} נוסף בהצלחה!</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" onClick={() => window.location.reload(false)}></button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        <span>יצרנו לינק ללומד על מנת שיוכל לעדכן את מצב הלימוד שלו, תצטרך לשתף איתו את הלינק.</span>
                                    </p>
                                    <p>
                                        <span>אם תשתף עם הלומדים את דף "הוסף לומד" והם יוסיפו את עצמם, לא תצטרך לשתף את הלינקים</span>
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                            onClick={() => window.location.reload(false)}>סגור
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => generalSharing()}>שתף
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => whatsappSharing()}>whatsapp
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Spinner/>);
    }
}

export default AddUserD;
