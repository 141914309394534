
import { doc, getDoc, addDoc, setDoc, collection, runTransaction } from "firebase/firestore";
import {db} from "../../../index";
import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from 'react-router-dom';
import globalMasehtot from "../../../masehtot.json"
import Spinner from "../../../spinner";
import Cookies from 'js-cookie';
import AddUserD from "../dapim/addUserD";

const AddUser = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [privateSelectedMasehtot, setPrivateSelectedMasehtot] = useState([]);
    const [privateSelectedChapters, setPrivateSelectedChapters] = useState([]);
    const [generalSelectedMasehtot, setGeneralSelectedMasehtot] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [phone, setPhone] = useState('');
    const [sharingUser, setSharingUser] = React.useState([]);
    const [newUser, setNewUser] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        masehtotValidation().then(siumObj => {
            if (siumObj) {
                const userSelections = getUserSelection();
                if (userSelections) {
                    const newUserObj = {
                        email: event.target.email.value,
                        phone: phone,
                        fullName: event.target.fullName.value,
                        nishmat: event.target.nishmat.value,
                        siumId: params.siumId,
                        lastModified: new Date(),
                        masehtot: userSelections.masehtot,
                        pagesNum: userSelections.pagesCounter
                    }
                    setNewUser(newUserObj);
                    addDoc(collection(db, "users"), newUserObj).then((docRef) => {
                        if (isAdmin) {
                            setSharingUser(docRef);
                            var myModal = document.getElementById('sharingModal');
                            var modal = window.bootstrap.Modal.getOrCreateInstance(myModal);
                            modal.show();
                        } else {
                            navigate("/updateStatus/" + docRef.id);
                        }
                    });

                } else {
                    displayErrorMessage(true, "ישנה בעיה בבחירת המסכתות")
                    setIsSubmitting(false);
                }
            } else {
                if (!errorMessage) {
                    displayErrorMessage(false, "ישנו כעת עומס בבחירת המסכתות, נסה בעוד מספר שניות")
                }
                setIsSubmitting(false);
            }
        })
    };

    function displayErrorMessage(reset, errorMsg) {
        if (reset) {
            setPrivateSelectedChapters([]);
            setPrivateSelectedMasehtot([])
        }
        setErrorMessage(errorMsg);
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    async function masehtotValidation() {
        const docRef = doc(db, "siumim", params.siumId);
        setErrorMessage("");

        try {
            return await runTransaction(db, async (transaction) => {
                const sium = await transaction.get(docRef);
                if (sium.exists()) {
                    const siumObj = sium.data();
                    setSium(siumObj);
                    const genSelection = siumObj.masehtotSelection;
                    setGeneralSelectedMasehtot(genSelection);
                    let validationResult = true;
                    if (privateSelectedChapters.length === 0) {
                        validationResult = false;
                        displayErrorMessage(true, "לא נבחרו מסכתות");
                        return null;
                    }
                    for (const i in privateSelectedChapters) {
                        const selection = privateSelectedChapters[i].split("-");
                        if (!selection[0] || !selection[1] || genSelection[selection[0]][selection[1]]) {
                            validationResult = false;
                            displayErrorMessage(true, "כנראה שבחרת מסכתות שנתפסו, נסה לבחור שוב")
                            return null;
                        }
                        genSelection[selection[0]][selection[1]] = true;
                    }
                    if (validationResult) {
                        siumObj.masehtotSelection = genSelection;
                        siumObj.lastAccessed = new Date().getTime();
                        transaction.update(docRef, siumObj);
                        return siumObj;
                    }
                    return null;
                } else {
                    displayErrorMessage(true, "נראה שסיום השס הנוכחי לא קיים");
                    return null;
                }
            });
        } catch (e) {
            displayErrorMessage(false, "ישנו כעת עומס בבחירת המסכתות, נסה בעוד מספר שניות")
            console.log("Transaction failed: ", e);
            return null;
        }
    }

    function getUserSelection() {
        let masehtotSelection = [];
        let specificMasehet = {};
        let pagesCounter = 0;
        for (const i in privateSelectedChapters.sort()) {
            const selection = privateSelectedChapters[i].split("-");
            const masehetKey = selection[0];
            const chapterNum = selection[1];
            const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
            if (currentMasehet) {
                const currentChapter = currentMasehet.chapters[chapterNum];
                if (currentChapter) {
                    let pagesInChapter = [];

                    if (currentMasehet.isMishna) {
                        pagesInChapter = Array.from(
                            { length: currentChapter },
                            (_, i) => globalMasehtot.allChapters[i]
                        );
                    } else {
                        const startIndex = globalMasehtot.onlyPages.indexOf(currentChapter.startPage.replace(/[.:]/g, ""));
                        const endIndex = globalMasehtot.onlyPages.indexOf(currentChapter.endPage.replace(/[.:]/g, ""));
                        pagesInChapter = Array.from(
                            {length: endIndex - startIndex + 1},
                            (_, i) => globalMasehtot.onlyPages[startIndex + i]
                        );
                    }
                    pagesCounter += pagesInChapter.length;
                    let finish = [];
                    for (let i = 0; i < pagesInChapter.length; i++) {finish.push(false)}
                    // Continue with same chapter
                    if (specificMasehet.masehetId !== masehetKey) {
                        // User select one more masehet
                        if (specificMasehet.masehetId) {
                            masehtotSelection.push(specificMasehet);
                        }
                        specificMasehet = {
                            masehetId: masehetKey,
                            chapters: [],
                        }
                    }
                    specificMasehet.chapters.push({chapterNum: parseInt(chapterNum) + 1, finish: finish})
                }
            }
        }
        if (specificMasehet.masehetId) {
            masehtotSelection.push(specificMasehet);
        }
        return {masehtot: masehtotSelection, pagesCounter: pagesCounter};
    }

    function handleMasehetSelection(key, action) {
        if (action === "remove") {
            setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((c) => c !== key));
            const newPrivateSelectionC = privateSelectedChapters.filter((p) => !p.startsWith(key));
            setPrivateSelectedChapters(newPrivateSelectionC);
        } else {
            const currentGeneralSelection = generalSelectedMasehtot[key];
            let newPrivateSelectionC = privateSelectedChapters;
            for (const chap_i in currentGeneralSelection) {
                if (!currentGeneralSelection[chap_i]) {
                    newPrivateSelectionC.push(key + "-" + chap_i);
                }
            }
            setPrivateSelectedChapters(newPrivateSelectionC);
            if (action === "all") {
                setPrivateSelectedMasehtot([...privateSelectedMasehtot, key])
            } else {
                setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((m) => m !== key));
            }
        }
    }

    function handleChapterSelection(m_key, c_i) {
        const chapterVal = m_key + "-" + c_i;
        let newPrivateSelectionC = [];
        if (privateSelectedChapters.includes(chapterVal)) {
            newPrivateSelectionC = privateSelectedChapters.filter((p) => p !== chapterVal);
            if (privateSelectedMasehtot.includes(m_key)) {
                setPrivateSelectedMasehtot(privateSelectedMasehtot.filter((c) => c !== m_key));
            }
        } else {
            newPrivateSelectionC = [...privateSelectedChapters, chapterVal];
            let wholeMasehetSelected = true;
            const currentGeneralSelection = generalSelectedMasehtot[m_key];
            for (const chap_i in currentGeneralSelection) {
                if (!privateSelectedChapters.includes(m_key + "-" + chap_i)) {
                    wholeMasehetSelected = false;
                    break;
                }
            }
            if (wholeMasehetSelected) {
                setPrivateSelectedMasehtot([...privateSelectedMasehtot, m_key])
            }
        }
        setPrivateSelectedChapters(newPrivateSelectionC);
    }

    function sharePage() {
        if (navigator.share) {
            if (sium.displayId) {
                navigator.share({
                    title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                    text: 'מצב הש"ס של "' + sium.kehilaName + '"',
                    url: '/displaySium/' + sium.displayId
                });
            } else {
                navigator.share({
                    title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                    text: 'הוספת לומד חדש',
                    url: '/addUser/' + params.siumId
                });
            }
        }
    }

    function generalSharing() {
        if (navigator.share) {
            navigator.share({
                title: 'סיום הש"ס של "' + sium.kehilaName + '"',
                text: sharingUser.fullName,
                url: '/updateStatus/' + sharingUser.id
            });
        }
    }

    function whatsappSharing() {
        if (/^[0-9-]+$/.test(newUser.phone)) {
            const whatsappLink = 'https://wa.me/972' + newUser.phone.replace('-', '') + '?text=' + encodeURIComponent('https://siumshas.co.il/updateStatus/' + sharingUser.id);
            window.open(whatsappLink, '_blank');
        } else {
            return;
        }
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
    };

    useEffect(() => {
        const docRef = doc(db, "siumim", params.siumId);
        getDoc(docRef).then(sium => {
            if (sium) {
                setSium(sium.data());
                setGeneralSelectedMasehtot(sium.data().masehtotSelection);
                const siumShasId = Cookies.get('siumShasId');
                if (siumShasId && siumShasId === sium.id) {
                    setIsAdmin(true);
                }
            }
        });

    },[params.siumId]);

    if (sium && sium.kehilaName && globalMasehtot.allMasehtot) {
        if (sium.haluka === 'dapim') {
            return (<AddUserD/>)
        } else {
            const masehtotKeysList = Object.keys(sium.masehtotSelection)
                .sort((a, b) => globalMasehtot.allMasehtot[a].index - globalMasehtot.allMasehtot[b].index);
            const masehtotList = masehtotKeysList.map((key, index) => {
                const currentMasehet = globalMasehtot.allMasehtot[key];
                const currentGeneralSelection = generalSelectedMasehtot[key];
                const chapters = globalMasehtot.allChapters;
                let allMasehetWasSelected = true;
                let partialMasehetWasSelected = false;
                const chaptersForMasehet = currentGeneralSelection.map((selected, c_i) => {
                    const chapterName = chapters[c_i];
                    const chapterId = key + "-" + c_i

                    if (selected) {
                        partialMasehetWasSelected = true;
                    } else if (!privateSelectedChapters.includes(chapterId)) {
                        allMasehetWasSelected = false;
                    }

                    return <>
                        <input type="checkbox" className="btn-check" id={chapterId}
                               checked={privateSelectedChapters.includes(chapterId)}
                               onChange={() => handleChapterSelection(key, c_i)} disabled={selected}/>
                        <label className="btn btn-outline-primary" htmlFor={chapterId}>{chapterName}</label>
                    </>
                })

                const selectedChaptersCounter = privateSelectedChapters.filter((c) => c.startsWith(key)).length +
                    currentGeneralSelection.filter((c) => c).length;

                return <div className="accordion-item" key={key + "-masehet"}>
                    <h3 className="accordion-header">
                        <button
                            className={`accordion-button collapsed ${privateSelectedChapters.some((p) => p.startsWith(key)) ? 'selected-masehet-color' : ''}`}
                            type="button" data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + key} aria-expanded="false" aria-controls={"collapse" + key}>
                            <span className="masehet-title">{currentMasehet.name}</span>
                            {currentMasehet.isMishna && <span className="badge bg-success margin-right-10">משנה</span>}
                            {allMasehetWasSelected && <span className="badge bg-info margin-right-10">המסכת נתפסה</span>}
                            {!allMasehetWasSelected && <span className="badge bg-primary margin-right-10">
                                {currentGeneralSelection.length} / {selectedChaptersCounter} פרקים נבחרו
                            </span>}
                        </button>
                    </h3>
                    <div id={"collapse" + key} className="accordion-collapse collapse" data-bs-parent="#masehtotList">
                        <div className="accordion-body">
                            <div className="btn-group margin-bottom-10 chapters-options" role="group"
                                 aria-label="Basic radio toggle button group">
                                <input type="radio" className="btn-check" name={key + "Selection"} id={key + "AllMasehet"}
                                       value={key + "AllMasehet"}
                                       disabled={allMasehetWasSelected || partialMasehetWasSelected}
                                       onChange={() => handleMasehetSelection(key, "all")}
                                       checked={privateSelectedMasehtot.includes(key)}/>
                                <label className="btn btn-outline-primary" htmlFor={key + "AllMasehet"}>בחירת כל
                                    המסכת</label>

                                <input type="radio" className="btn-check" name={key + "Selection"}
                                       id={key + "PartialMasehet"} disabled={allMasehetWasSelected}
                                       value={key + "PartialMasehet"}
                                       onChange={() => handleMasehetSelection(key, "partial")}
                                       checked={!privateSelectedMasehtot.includes(key) && privateSelectedChapters.some((c) => c.startsWith(key))}/>
                                <label className="btn btn-outline-primary" htmlFor={key + "PartialMasehet"}>בחירת פרקים
                                    מסוימים</label>

                                <input type="radio" className="btn-check" name={key + "Selection"} id={key + "NoMasehet"}
                                       disabled={allMasehetWasSelected}
                                       value={key + "NoMasehet"} onChange={() => handleMasehetSelection(key, "remove")}
                                       checked={!privateSelectedChapters.some((c) => c.startsWith(key))}/>
                                <label className="btn btn-outline-primary" htmlFor={key + "NoMasehet"}>מסכת לא נבחרה</label>
                            </div>
                            <div id={key + "Chapters"}>
                                {chaptersForMasehet}
                            </div>
                            <div className="accordion-item margin-top-20" id={key + "-Masehet-dapim"}>
                                <h3 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target={"#collapseTaken" + key + "Dapim"} aria-expanded="false" aria-controls={"collapseTaken" + key + "Dapim"}>
                                        פירוט דפי המסכת
                                    </button>
                                </h3>
                                <div id={"collapseTaken" + key + "Dapim"} className="accordion-collapse collapse" data-bs-parent={`#${key}-Masehet-dapim`}>
                                    <div className="accordion-body perut-dapim-container">
                                        {!globalMasehtot.allMasehtot[key].isMishna && globalMasehtot.allMasehtot[key].chapters.map((chapter, c_i) => {
                                            const chapterId = `${key}-${c_i}`;
                                            const chapterName = chapters[c_i];
                                            const startIndex = globalMasehtot.onlyPages.indexOf(chapter.startPage.replace(/[.:]/g, ""));
                                            const endIndex = globalMasehtot.onlyPages.indexOf(chapter.endPage.replace(/[.:]/g, ""));
                                            const chapterPages = Array.from(
                                                { length: endIndex - startIndex + 1 },
                                                (_, i) => globalMasehtot.onlyPages[startIndex + i]
                                            );
                                            return (
                                                <>
                                                    <div>
                                                        <input type="checkbox" className="btn-check" id={chapterId} disabled={true}
                                                               checked={privateSelectedChapters.includes(chapterId)}/>
                                                        <label className="btn btn-outline-primary perut-dapim-perek" htmlFor={chapterId}>{chapterName}</label>
                                                    </div>
                                                    <div className="perut-dapim-list">
                                                        {chapterPages.map((pageName, p_index) => {
                                                            return(<>
                                                                <input type="checkbox" className="btn-check" id={chapterId} disabled={true}
                                                                       checked={privateSelectedChapters.includes(chapterId)}/>
                                                                <label className="btn btn-outline-primary daf-button-little" htmlFor={chapterId}>{pageName}</label>
                                                            </>);
                                                        })}
                                                    </div>
                                                </>
                                            );
                                        })}
                                        {globalMasehtot.allMasehtot[key].isMishna && globalMasehtot.allMasehtot[key].chapters.map((mishnaCount, c_i) => {
                                            const chapterId = `${key}-${c_i}`;
                                            const chapterName = chapters[c_i];
                                            const chapterPages = Array.from(
                                                { length: mishnaCount },
                                                (_, i) => globalMasehtot.allMishnayot[i]
                                            );
                                            return (
                                                <>
                                                    <div>
                                                        <input type="checkbox" className="btn-check" id={chapterId} disabled={true}
                                                               checked={privateSelectedChapters.includes(chapterId)}/>
                                                        <label className="btn btn-outline-primary perut-dapim-perek" htmlFor={chapterId}>{chapterName}</label>
                                                    </div>
                                                    <div className="perut-dapim-list">
                                                        {chapterPages.map((pageName, p_index) => {
                                                            return(<>
                                                                <input type="checkbox" className="btn-check" id={chapterId} disabled={true}
                                                                       checked={privateSelectedChapters.includes(chapterId)}/>
                                                                <label className="btn btn-outline-primary mishna-button-little" htmlFor={chapterId}>{pageName}</label>
                                                            </>);
                                                        })}
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            });
            let keyPage = 0;
            return (
                <div className="sium-main" key={keyPage}>
                    <div className="sium-main-container">
                        <div className="basad-with-return">
                            <div className="basad">
                                <span>בס"ד</span>
                                <br/>
                                <span>להצלחת עם ישראל</span>
                            </div>
                            {isAdmin && <div className="return-container">
                                <a className="btn sium-button return-button"
                                   href={"/niulSium/" + params.siumId + "/siumStatus"}>חזור</a>
                            </div>}
                        </div>
                        <div className="sium-logo">
                            <a className="sium-logo-img" href={"/"} aria-label="Main page"></a>
                        </div>
                        <div
                            className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                            <h2>סיום הש"ס של {sium.kehilaName}</h2>
                            <h4>הוספת לומד חדש</h4>
                            {sium.logo && <div alt="logo" className={sium.logo}/>}
                        </div>
                        {isAdmin && <div className="gap-2 col-8 mx-auto sharing-tip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.66666in" height="2.66666in" version="1.1"
                                 viewBox="0 0 2667 2667" className="col-1 gap-2">
                                <g id="Layer_x0020_1">
                                    <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                                    <circle className="fil0" cx="1333" cy="1333" r="1333" id="id_101"></circle>
                                    <path className="fil1"
                                          d="M1140 2086l-263 0c-13,0 -23,-10 -23,-23 0,-13 10,-23 23,-23l263 0c12,0 22,10 22,23 0,13 -10,23 -22,23zm-498 -1349c-8,0 -16,-4 -20,-12l-57 -98c-6,-10 -2,-24 9,-31 11,-6 25,-2 31,9l56 98c7,10 3,24 -8,31 -4,2 -7,3 -11,3zm176 -74c-10,0 -19,-6 -22,-16l-29 -109c-3,-13 4,-25 16,-28 12,-3 25,4 28,16l29 109c3,12 -4,24 -16,28 -2,0 -4,0 -6,0zm190 -24c-13,0 -23,-11 -23,-23l0 -113c0,-13 10,-23 23,-23 12,0 23,10 23,23l0 113c0,12 -11,23 -23,23zm356 693c-3,0 -7,-1 -10,-2 -8,-4 -13,-12 -13,-21l1 -167 -46 0c-12,0 -23,-11 -23,-23l0 -560c0,-13 11,-23 23,-23l820 0c13,0 23,10 23,23l0 560c0,12 -10,23 -23,23l-502 0 -236 185c-4,3 -9,5 -14,5zm633 -366c7,0 12,-6 12,-13 0,-7 -5,-13 -12,-13l-581 0c-7,0 -13,6 -13,13 0,7 6,13 13,13l581 0zm0 -124c7,0 12,-6 12,-13 0,-7 -5,-12 -12,-12l-581 0c-7,0 -13,5 -13,12 0,7 6,13 13,13l581 0zm-75 -123c7,0 12,-6 12,-13 0,-7 -5,-13 -12,-13l-431 0c-7,0 -13,6 -13,13 0,7 6,13 13,13l431 0zm-733 1259l-361 0c-13,0 -23,-10 -23,-23 0,-358 -83,-424 -157,-483 -62,-49 -120,-95 -120,-273 0,-265 215,-481 480,-481 84,0 167,23 239,64l0 337c0,27 22,49 49,49l19 0 0 141c0,19 10,36 27,45 7,3 14,5 22,5 11,0 22,-4 30,-11l90 -70c-15,115 -64,154 -116,194 -73,59 -157,125 -157,483 0,13 -10,23 -22,23zm-549 -779c0,61 2,121 52,165 10,9 21,17 32,25 11,10 23,19 34,29 33,30 60,64 82,103 2,4 6,6 11,6 2,0 4,0 6,-1 6,-3 8,-11 5,-17 -23,-42 -52,-78 -87,-110 -11,-10 -23,-20 -36,-30 -10,-8 -20,-16 -30,-24 -43,-38 -43,-93 -43,-146 0,-189 153,-343 342,-343 63,0 125,18 179,51 6,3 14,1 18,-5 3,-6 1,-13 -4,-17 -58,-36 -125,-54 -193,-54 -203,0 -368,165 -368,368zm431 988l-126 0c-12,0 -22,-11 -22,-23 0,-13 10,-23 22,-23l126 0c12,0 23,10 23,23 0,12 -10,23 -23,23z"
                                          id="id_102"></path>
                                </g>
                            </svg>
                            <span>שתף עם החבר'ה שיוסיפו את עצמם</span>
                            <button className="btn" type="button" onClick={() => sharePage()}>שתף</button>
                        </div>}
                        <form onSubmit={handleSubmit}>
                            <input className="form-control" type="text" placeholder="שם מלא" name="fullName"
                                   aria-label="Full name" required={true}/>
                            <input className="form-control" type="text" placeholder="פלאפון" name="phone"
                                   onChange={handlePhoneChange} value={phone}
                                   aria-label="Phone" required={true}/>
                            <input className="form-control" type="text" placeholder="אימייל" name="email"
                                   aria-label="Email"/>
                            <input className="form-control" type="text" placeholder="לעילוי נשמת/לרפואת/להצלחת"
                                   name="nishmat" aria-label="Nishmat"/>
                            <div className="accordion" id="masehtotList">
                                {masehtotList}
                            </div>
                            <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                                <div id="errorModal" className="toast" role="alert" aria-live="assertive"
                                     aria-atomic="true">
                                    <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
                                            aria-label="Close"></button>
                                    <div className="toast-body">
                                        {errorMessage}
                                    </div>
                                </div>
                            </div>
                            {privateSelectedChapters.length > 0 && !isSubmitting && <div className="sium-button-container sticky-bottom">
                                <button className="btn sium-button" type="submit">הוסף</button>
                            </div>}
                            {isSubmitting && <div className="sium-button-container sticky-bottom">
                                <button type="button" className="btn sium-button">
                                    <div className="updateStatusSpinner"></div>
                                </button>
                            </div>}
                        </form>
                        <div className="modal fade modal-dialog-scrollable" id="sharingModal" data-bs-backdrop="static"
                             data-bs-keyboard="false"
                             tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            {sharingUser && newUser && <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5">{newUser.fullName} נוסף בהצלחה!</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close" onClick={() => window.location.reload(false)}></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>
                                            <span>יצרנו לינק ללומד על מנת שיוכל לעדכן את מצב הלימוד שלו, תצטרך לשתף איתו את הלינק.</span>
                                        </p>
                                        <p>
                                            <span>אם תשתף עם הלומדים את דף "הוסף לומד" והם יוסיפו את עצמם, לא תצטרך לשתף את הלינקים</span>
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                                onClick={() => window.location.reload(false)}>סגור
                                        </button>
                                        <button type="button" className="btn btn-primary"
                                                onClick={() => generalSharing()}>שתף
                                        </button>
                                        <button type="button" className="btn btn-primary"
                                                onClick={() => whatsappSharing()}>whatsapp
                                        </button>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="sium-bottom-page margin-top-20">
                            <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                        </div>
                    </div>
                </div>
            )
        }

    } else {
        return (<Spinner/>);
    }
}

export default AddUser;
