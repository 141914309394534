
import {doc, getDoc, runTransaction, setDoc} from "firebase/firestore";
import {db} from "../../../index";
import React, {useEffect, useState} from "react"
import {useParams} from 'react-router-dom';
import globalMasehtot from "../../../masehtot.json"
import { useNavigate } from "react-router-dom";
import Spinner from "../../../spinner";
import Cookies from "js-cookie";

const EditUserD = () => {

    const params = useParams();
    const [sium, setSium] = useState([]);
    const [currUser, setCurrUser] = useState([]);
    const [privateSelection, setPrivateSelection] = useState([]);
    const [oldPrivateSelection, setOldPrivateSelection] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [haveChanges, setHaveChanges] = useState(false)
    const [phone, setPhone] = useState('');
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        masehtotValidation().then(siumObj => {
            if (siumObj) {
                const newUserObj = {
                    email: event.target.email.value,
                    phone: phone,
                    fullName: event.target.fullName.value,
                    nishmat: event.target.nishmat.value,
                    siumId: currUser.siumId,
                    lastModified: new Date(),
                    pages: privateSelection.reduce((acc, item) => {
                        acc[item] = false;
                        return acc;
                    }, {}),
                    pagesNum: privateSelection.length
                }
                if (sium.shemMehina) {
                    newUserObj.mehinaName = event.target.mehinaName.value;
                }
                setPrivateSelection([]);
                const docRef = doc(db, "users", params.userId);
                setDoc(docRef, newUserObj).then(() => {
                    if (isAdmin) {
                        navigate("/niulSium/" + currUser.siumId + "/siumStatus");
                    } else {
                        navigate("/updateStatus/" + params.userId);
                    }
                })
            } else {
                setIsSubmitting(false);
            }
        })
    };

    function displayErrorMessage(reset, errorMsg) {
        if (reset) {
            setPrivateSelection([]);
        }
        setErrorMessage(errorMsg);
        const toastModal = document.getElementById('errorModal');
        const toastBootstrap = window.bootstrap.Toast.getOrCreateInstance(toastModal);
        toastBootstrap.show();
    }

    async function masehtotValidation() {
        const docRef = doc(db, "siumim", currUser.siumId);

        try {
            return await runTransaction(db, async (transaction) => {
                const sium = await transaction.get(docRef);
                if (sium.exists()) {
                    const siumObj = sium.data();
                    setSium(siumObj);
                    const siumSelection = siumObj.masehtotSelection;
                    let validationResult = true;
                    if (privateSelection.length === 0) {
                        validationResult = false;
                        displayErrorMessage(true, "לא נבחרו מסכתות");
                        return null;
                    }
                    for (const i in privateSelection.sort()) {
                        if (!oldPrivateSelection.includes(privateSelection[i])) {
                            const selection = privateSelection[i].split("-");
                            const masehetKey = selection[0];
                            const pageNum = parseInt(selection[1]);
                            const index = siumSelection.findIndex(obj => Object.keys(obj)[0] === masehetKey);
                            if (!selection[0] || !selection[1] || siumSelection[index][masehetKey][pageNum]) {
                                validationResult = false;
                                displayErrorMessage(true, "כנראה שבחרת מסכתות שנתפסו, נסה לבחור שוב")
                                return null;
                            }
                            siumSelection[index][masehetKey][pageNum] = true;
                        }
                    }
                    for (const i in oldPrivateSelection.sort()) {
                        if (!privateSelection.includes(oldPrivateSelection[i])) {
                            const selection = oldPrivateSelection[i].split("-");
                            const masehetKey = selection[0];
                            const pageNum = parseInt(selection[1]);
                            const index = siumSelection.findIndex(obj => Object.keys(obj)[0] === masehetKey);
                            if (siumSelection[index][masehetKey]) {
                                siumSelection[index][masehetKey][pageNum] = false;
                            }
                        }
                    }
                    if (validationResult) {
                        siumObj.masehtotSelection = siumSelection;
                        siumObj.lastAccessed = new Date().getTime();
                        transaction.update(docRef, siumObj);
                        return siumObj;
                    }
                    return null;
                } else {
                    displayErrorMessage(true, "נראה שסיום השס הנוכחי לא קיים");
                    return null;
                }
            });
        } catch (e) {
            displayErrorMessage(false, "ישנו כעת עומס בבחירת המסכתות, נסה בעוד מספר שניות ")
            console.log("Transaction failed: ", e);
            return null;
        }
    }

    function handleWholeMasehet(m_key, isWholeMasehet) {
        if (isWholeMasehet) {
            const newPrivateSelection = privateSelection.filter((p) => !p.startsWith(m_key));
            setPrivateSelection(newPrivateSelection);
        } else {
            const newPrivateSelection = [...privateSelection];
            sium.masehtotSelection.filter(obj => Object.keys(obj)[0] === m_key).map((masehetSelection, m_i) => {
                for (let i = 0; i < masehetSelection[m_key].length; i++) {
                    const pageVal = m_key + "-" + i;
                    if ((!masehetSelection[m_key][i] || oldPrivateSelection.includes(pageVal)) && !privateSelection.includes(pageVal)) {
                        newPrivateSelection.push(pageVal);
                    }
                }
                return;
            });
            setPrivateSelection(newPrivateSelection);
        }
    }

    function handlePageSelection(m_key, p_i) {
        const pageVal = m_key + "-" + p_i;
        let newPrivateSelectionC = [];
        if (privateSelection.includes(pageVal)) {
            newPrivateSelectionC = privateSelection.filter((p) => p !== pageVal);
        } else {
            newPrivateSelectionC = [...privateSelection, pageVal];
        }
        setPrivateSelection(newPrivateSelectionC);
        setHaveChanges(true);
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
        setHaveChanges(true);
    };

    useEffect(() => {
        const docRef = doc(db, "users", params.userId);
        getDoc(docRef).then(user => {
            if (user) {
                setCurrUser(user.data())
                setPhone(user.data().phone);
                setPrivateSelection(Object.keys(user.data().pages));
                setOldPrivateSelection(Object.keys(user.data().pages));
                const docSiumRef = doc(db, "siumim", user.data().siumId);
                getDoc(docSiumRef).then(sium => {
                    if (sium) {
                        setSium(sium.data());
                        const siumShasId = Cookies.get('siumShasId');
                        if (siumShasId && siumShasId === sium.id) {
                            if (sium.data().redirectNiul) {
                                const siumPhone = Cookies.get('siumPhone');
                                if (siumPhone) {
                                    setIsAdmin(true);
                                }
                            } else {
                                setIsAdmin(true);
                            }
                        }
                    }
                });
            }
        });
    },[params.userId]);

    if (currUser && currUser.fullName && sium && sium.kehilaName && globalMasehtot.allMasehtot) {
        const pagesNames = globalMasehtot.onlyPages;
        const masehtotList = sium.masehtotSelection
            .sort((a, b) => globalMasehtot.allMasehtot[Object.keys(a)[0]].index - globalMasehtot.allMasehtot[Object.keys(b)[0]].index)
            .map((siumMasehetSelection, m_i) => {
            const masehetKey = Object.keys(siumMasehetSelection)[0];
            const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
            let masehetPagesTakenCounter = 0;
            let masehetPagesCounter = siumMasehetSelection[masehetKey].length;
            const startIndex = !currentMasehet.canByChoose ? 0 :
                globalMasehtot.onlyPages.indexOf((currentMasehet.chapters[0].startPage).replace(/[.:]/g, ""))

            const pagesForMasehet = siumMasehetSelection[masehetKey].map((pageSelected, p_index) => {
                const pageName = currentMasehet.isMishna ? globalMasehtot.allChapters[p_index] : pagesNames[startIndex + p_index];
                const wasTaken = pageSelected;
                const pageId = `${masehetKey}-${p_index}`;
                if (wasTaken && !oldPrivateSelection.includes(pageId)) {
                    masehetPagesTakenCounter++;
                }

                return <>
                    {!currentMasehet.isMishna && <button type="button" id={pageId} disabled={wasTaken && !oldPrivateSelection.includes(pageId)}
                            className={`btn daf-button ${privateSelection.includes(pageId) ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => handlePageSelection(masehetKey, p_index)}>{pageName}</button>}
                    {currentMasehet.isMishna && <button type="button" id={pageId} disabled={wasTaken && !oldPrivateSelection.includes(pageId)}
                            className={`btn mishna-button ${privateSelection.includes(pageId) ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={() => handlePageSelection(masehetKey, p_index)}>{pageName}</button>}
                </>
            })

            masehetPagesTakenCounter += privateSelection.filter(pageId => pageId.startsWith(masehetKey)).length;
            const displayWholeMasehet = masehetPagesTakenCounter < masehetPagesCounter || privateSelection.some((p) => p.startsWith(masehetKey));

            return <div className="accordion-item" key={masehetKey + "-masehet"}>
                <h3 className="accordion-header">
                    <button className={`accordion-button collapsed ${privateSelection.some((p) => p.startsWith(masehetKey)) ? 'selected-masehet-color' : ''}`} type="button" data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + masehetKey} aria-expanded="false" aria-controls={"collapse" + masehetKey}>
                        <span className="masehet-title">{currentMasehet.name}</span>
                        {currentMasehet.isMishna && <span className="badge bg-success margin-right-10">משנה</span>}
                        {masehetPagesTakenCounter === masehetPagesCounter && <span className="badge bg-info margin-right-10">המסכת נתפסה</span>}
                        {masehetPagesTakenCounter < masehetPagesCounter && <span className="badge bg-primary margin-right-10">{masehetPagesCounter} / {masehetPagesTakenCounter} נתפסו</span>}
                    </button>
                </h3>
                <div id={"collapse" + masehetKey} className="accordion-collapse collapse" data-bs-parent="#masehtotList">
                    <div className="accordion-body">
                        {displayWholeMasehet && <div className="form-check form-switch finish-all-chapter right-text">
                            <input className="form-check-input r_float" type="checkbox"
                                   id={"all-" + masehetKey} checked={masehetPagesCounter === masehetPagesTakenCounter}
                                   onClick={() => {handleWholeMasehet(masehetKey, masehetPagesCounter === masehetPagesTakenCounter);setHaveChanges(true)}} />
                            <label className="form-check-label" htmlFor={"all-" + masehetKey}>לסמן את כל המסכת</label>
                        </div>}
                        <div id={masehetKey + "Chapters"}>
                            {pagesForMasehet}
                        </div>
                    </div>

                </div>
            </div>
        });
        return (
            <div className="sium-main" key='addUserPage'>
                <div className="sium-main-container">
                    <div className="basad-with-return">
                        <div className="basad">
                            <span>בס"ד</span>
                            <br/>
                            <span>להצלחת עם ישראל</span>
                        </div>
                        <div className="return-container">
                            <a className="btn sium-button return-button"
                               href={isAdmin ? "/niulSium/" + currUser.siumId + "/siumStatus" : "/updateStatus/" + params.userId}>חזור</a>
                        </div>
                    </div>
                    <div className="sium-logo">
                        <a className="sium-logo-img" href={"/"} aria-label="Main page"></a>
                    </div>
                    <div
                        className="grid p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3 sium-titles">
                        <h2>סיום הש"ס של {sium.kehilaName}</h2>
                        <h4>עריכת לומד</h4>
                        {sium.logo && <div alt="logo" className={sium.logo}/>}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input className="form-control" type="text" placeholder="שם מלא" name="fullName" defaultValue={currUser.fullName}
                               aria-label="Full name" required={true} onChange={() => setHaveChanges(true)}/>
                        <input className="form-control" type="text" placeholder="פלאפון" name="phone" value={phone}
                               aria-label="Phone" required={true} onChange={handlePhoneChange}/>
                        <input className="form-control" type="text" placeholder="אימייל" name="email" defaultValue={currUser.email}
                               aria-label="Email" onChange={() => setHaveChanges(true)}/>
                        {sium.shemMehina && <input className="form-control" type="text" placeholder="שם המכינה" name="mehinaName" aria-label="Mehina name"
                                required={true} defaultValue={currUser.mehinaName} onChange={() => setHaveChanges(true)}/>}
                        <input className="form-control" type="text" placeholder="לעילוי נשמת/לרפואת/להצלחת" defaultValue={currUser.nishmat}
                               name="nishmat" aria-label="Nishmat" onChange={() => setHaveChanges(true)}/>
                        <div className="accordion" id="masehtotList">
                            {masehtotList}
                        </div>
                        <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
                            <div id="errorModal" className="toast" role="alert" aria-live="assertive"
                                 aria-atomic="true">
                                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
                                        aria-label="Close"></button>
                                <div className="toast-body">
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                        {haveChanges > 0 && !isSubmitting && <div className="sium-button-container sticky-bottom">
                            <button className="btn sium-button" type="submit">עדכן</button>
                        </div>}
                        {isSubmitting && <div className="sium-button-container sticky-bottom">
                            <button type="button" className="btn sium-button">
                                <div className="updateStatusSpinner"></div>
                            </button>
                        </div>}
                    </form>
                    <div className="sium-bottom-page margin-top-20">
                        <p><span> © כל הזכויות שמורות לאתר סיום הש"ס</span></p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<Spinner/>);
    }
}

export default EditUserD;
