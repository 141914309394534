
const siumProgress = (props) => {

    function getPercentageClass(perc) {
        let siumProgressClasses = ["progress", "green"];
        const percentage = !perc ? "percent-0" : (perc < 15 ? "percent-10" :(perc < 25 ? "percent-20" :
            (perc < 35 ? "percent-30" : (perc < 45 ? "percent-40" : (perc < 55 ? "percent-50" : (perc < 65 ? "percent-60" :
                (perc < 75 ? "percent-70" : (perc < 85 ? "percent-80" : (perc < 95 ? "percent-90" : "percent-100")))))))));
        siumProgressClasses.push(percentage);
        return siumProgressClasses.join(' ');
    }

    if (props.dapim) {
        return(
            <div>
                <div className="container sium-progress-container">
                    <div className="row">
                        <div className="progress-container">
                            <div className={getPercentageClass(props.takenPages)}>
                                <span className="progress-left">
                                    <span className="progress-bar"></span>
                                </span>
                                <span className="progress-right">
                                    <span className="progress-bar"></span>
                                </span>
                                <div className="progress-value pulse">{props.takenPages}%</div>
                            </div>
                            <p className="bold progress-message">דפים נלקחו</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="progress-container">
                            <div className={getPercentageClass(props.learnedPages)}>
                                <span className="progress-left">
                                    <span className="progress-bar"></span>
                                </span>
                                <span className="progress-right">
                                    <span className="progress-bar"></span>
                                </span>
                                <div className="progress-value pulse">{props.learnedPages}%</div>
                            </div>
                            <p className="bold progress-message">דפים נלמדו</p>
                        </div>
                    </div>
                </div>
            </div>
        );

    } else {

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="progress-container">
                            <div className={getPercentageClass(props.siumPerc)}>
                                <span className="progress-left">
                                    <span className="progress-bar"></span>
                                </span>
                                <span className="progress-right">
                                    <span className="progress-bar"></span>
                                </span>
                                <div className="progress-value pulse">{props.siumPerc}%</div>
                            </div>
                            <p className="bold progress-message">{props.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default siumProgress;
