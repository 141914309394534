
import React, {useState} from "react"
import globalMasehtot from "../masehtot.json"
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {addDoc, collection} from "firebase/firestore";
import {db} from "../index";
import Spinner from "../spinner";

const NewSium = () => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [fieldsErrors, setFieldsErrors] = useState({});
    const [communityName, setCommunityName] = useState('');
    const [managerName, setManagerName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [dedication, setDedication] = useState('');
    const [haluka, setHaluka] = useState('masehtot');
    const [mishna, setMishna] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [includeMasehtot, setIncludeMasehtot] = useState({
        shkalim: true,
        kanim: true,
        tamid: true,
        midot: true,
    });
    const navigate = useNavigate();

    const submitNewSium = event => {
        event.preventDefault();

        setIsSubmitting(true);

        if (siumValidation()) {
            const newSium = {
                kehilaName: communityName,
                organizerName: managerName,
                organizerPhone: phone,
                organizerEmail: email,
                nishmat: dedication,
                created: new Date().getTime(),
                lastAccessed: new Date().getTime(),
                haluka: haluka,
                includeMishna: mishna,
            }
            saveIncludeMasehtot(newSium);
            if (haluka === 'masehtot') {
                newSium.startSium = startDate;
                newSium.endSium = endDate;
            }
            handleHaluka(newSium);
            addDoc(collection(db, "siumim"), newSium).then((docRef) => {
                sendEmail(newSium, docRef.id);
            });
        } else {
            setIsSubmitting(false);
        }

    }

    function saveIncludeMasehtot(sium) {
        sium.includeMasehtot = [];
        const masehtot = Object.keys(includeMasehtot);
        let pagesCounter = 0;

        if (haluka === 'dapim') {
            pagesCounter = 2675;
            for (const masehetKey of masehtot) {
                if (includeMasehtot[masehetKey]) {
                    sium.includeMasehtot.push(masehetKey);
                    const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
                    if (currentMasehet && currentMasehet.pagesForDapim) {
                        pagesCounter += currentMasehet.pagesForDapim;
                    }
                }
            }
        } else {
            pagesCounter = 2906;
            for (const masehetKey of masehtot) {
                if (includeMasehtot[masehetKey]) {
                    sium.includeMasehtot.push(masehetKey);
                    const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
                    if (currentMasehet && currentMasehet.pagesForMasehtot) {
                        pagesCounter += currentMasehet.pagesForMasehtot;
                    }
                }
            }
        }
        if (mishna) {
            pagesCounter += 196;
        }
        sium.pagesCounter = pagesCounter;
    }

    function handleHaluka(sium) {
        if (haluka === 'masehtot') {
            let masehetot = {};
            for (const masehetKey of Object.keys(globalMasehtot.masehtotSelection)) {
                const currentMasehet = globalMasehtot.allMasehtot[masehetKey];
                if (currentMasehet.canByChoose === undefined && currentMasehet.isMishna === undefined) {
                    masehetot[masehetKey] = globalMasehtot.masehtotSelection[masehetKey];
                } else if (currentMasehet.isMishna) {
                    if (mishna) {
                        masehetot[masehetKey] = globalMasehtot.masehtotSelection[masehetKey];
                    }
                } else if (sium.includeMasehtot.includes(masehetKey)) {
                    masehetot[masehetKey] = globalMasehtot.masehtotSelection[masehetKey];
                }
            }
            sium.masehtotSelection = masehetot;
        } else {
            let masehetot = [];
            globalMasehtot.newSiumDapim.forEach(obj => {
                Object.keys(obj).forEach(key => {
                    const currentMasehet = globalMasehtot.allMasehtot[key];
                    if (currentMasehet.canByChoose === undefined && currentMasehet.isMishna === undefined) {
                        masehetot.push(obj);
                    } else if (currentMasehet.isMishna) {
                        if (mishna) {
                            masehetot.push(obj);
                        }
                    } else if (sium.includeMasehtot.includes(key)) {
                        masehetot.push(obj);
                    }
                });
            });
            sium.masehtotSelection = masehetot;
        }
    }

    function sendEmail(siumObj, siumId) {
        var data = {
            service_id: 'service_ehcnv4y',
            template_id: 'template_t37vwnr',
            user_id: 'S00AQOC3z6jpB3gp_',
            template_params: {
                'to_name': siumObj.organizerName,
                'email_to': siumObj.organizerEmail,
                'kehila_name': siumObj.kehilaName,
                'nishmat': siumObj.nishmat,
                'sium_link': 'https://siumshas.co.il/niulSium/' + siumId + '/siumStatus',
                'sium_id': siumId
            }
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch('https://api.emailjs.com/api/v1.0/email/send', requestOptions)
            .then(async response => {
                if (response.ok) {
                    navigate("/niulSium/" + siumId + "/siumStatus");
                } else {
                    const error = (data && data.message) || response.status;
                    alert('There was an error!', error);
                }
            })
            .catch(error => {
                alert('There was an error!', error);
            });
    }

    function getTalkWithUsLink(user) {
        return 'https://wa.me/9720509552993?text=';
    }


    function siumValidation() {
        let newErrors = {};

        if (!communityName.trim()) {
            newErrors.communityName = 'נדרש שם הקהילה/הארגון';
        }

        if (!phone.trim()) {
            newErrors.phone = 'נדרש מספר הפלאפון';
        } else if (!/^\d{10}$/i.test(phone)) {
            newErrors.phone = 'מספר הפלאפון חייב להכיל 10 ספרות';
        }

        if (!managerName.trim()) {
            newErrors.managerName = 'נדרש שם המנהל';
        }

        if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newErrors.email = 'נדרש אימייל תקין';
        }

        if (haluka === 'masehtot') {
            if (!startDate || !endDate || (startDate.getTime() > endDate.getTime())) {
                newErrors.dates = 'נדרשת בחירת תאריכים תקינה';
            }
        }

        setFieldsErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        setPhone(numericValue);
    };

    const handleIcludeMasehtot = (e) => {
        const { id, checked } = e.target;
        setIncludeMasehtot((prev) => ({
            ...prev,
            [id]: checked,
        }));
    };

    if (globalMasehtot.masehtotSelection) {
        return (
            <div className="new-sium-container">
                <div className="logo-icon" onClick={() => window.location = "/"}>
                    <svg width="135" height="49" viewBox="0 0 135 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M86.3894 46.9941H85.3384L87.4833 41.3423H84.0193V39.4869H84.9845V40.5594H88.7702L86.3894 46.9941Z" fill="#1F2B70"/>
                            <path d="M82.7686 40.5594V44.2057H81.7605V40.5594H82.7686Z" fill="#1F2B70"/>
                            <path d="M74.643 40.5594H77.5172C79.351 40.5594 80.1017 41.353 80.1017 43.5944V46.9941H77.1096V46.2112H79.0936V43.6159C79.0936 41.9 78.6218 41.3423 77.3456 41.3423H76.6056L75.6189 46.9941H74.6001L75.6189 41.3423H74.643V40.5594Z" fill="#1F2B70"/>
                            <path d="M73.0038 40.5594V46.9941H71.9957V40.5594H73.0038Z" fill="#1F2B70"/>
                            <path d="M65.4352 40.5594H70.465V41.3423H69.6499V46.9941H68.6419V41.3423H65.4352V40.5594Z" fill="#1F2B70"/>
                            <path d="M55.5901 40.5594H58.4643C60.2982 40.5594 61.0489 41.353 61.0489 43.5944V46.9941H58.0568V46.2112H60.0408V43.6159C60.0408 41.9 59.5689 41.3423 58.2927 41.3423H57.5527L56.5661 46.9941H55.5472L56.5661 41.3423H55.5901V40.5594Z" fill="#1F2B70"/>
                            <path d="M48.8315 40.5594H51.8987C53.5502 40.5594 54.2259 41.16 54.2259 43.3049V46.9941H53.2178V43.3156C53.2178 41.7284 52.7888 41.3423 51.6306 41.3423H49.8396V46.9941H48.8315V40.5594Z" fill="#1F2B70"/>
                            <path d="M46.7955 40.5594V46.9941H45.7874V40.5594H46.7955Z" fill="#1F2B70"/>
                            <path d="M42.0379 46.9941H40.9869L43.1318 41.3423H39.6678V39.4869H40.633V40.5594H44.4187L42.0379 46.9941Z" fill="#1F2B70"/>
                            <path d="M33.0906 40.5594H38.4635V44.3023C38.4635 46.2863 37.7343 46.9941 36.0612 46.9941H35.3856V46.2112H35.9111C36.9621 46.2112 37.4662 45.8144 37.4662 44.313V41.3423H33.0906V40.5594ZM33.1549 48.6028V43.2298H34.163V48.6028H33.1549Z" fill="#1F2B70"/>
                            <path d="M27.9071 40.5594V46.2112H28.7758V46.9941H23.3707V46.2112H26.899V41.3423H23.3707V40.5594H27.9071Z" fill="#1F2B70"/>
                            <path d="M16.5361 40.5594H21.909V44.3023C21.909 46.2863 21.1798 46.9941 19.5067 46.9941H18.8311V46.2112H19.3566C20.4076 46.2112 20.9116 45.8144 20.9116 44.313V41.3423H16.5361V40.5594ZM16.6004 48.6028V43.2298H17.6085V48.6028H16.6004Z" fill="#1F2B70"/>
                            <path d="M12.9795 46.9941H11.9285L14.0734 41.3423H10.6094V39.4869H11.5746V40.5594H15.3604L12.9795 46.9941Z" fill="#1F2B70"/>
                            <path d="M9.25981 40.5594V46.9941H8.25171V40.5594H9.25981Z" fill="#1F2B70"/>
                            <path d="M0.484871 40.5594H3.93815C5.58971 40.5594 6.35115 41.2351 6.35115 43.3049V46.9941H5.34305V43.3907C5.34305 41.8142 4.82827 41.3423 3.72366 41.3423H2.40455V46.9941H0.120239V46.2112H1.43935V41.3423H0.484871V40.5594Z" fill="#1F2B70"/>
                            <path d="M78.8004 23.4675C78.2379 23.4675 77.5854 23.1975 77.5854 22.365V19.575C77.5854 19.1025 77.9679 18.8325 78.2829 18.8325C78.3054 19.3275 78.7554 19.575 79.2054 19.575H85.7304C87.6879 19.575 88.6104 20.835 88.6104 22.6575V28.98L87.0354 32.6925L79.1154 32.6475C78.6429 31.5675 77.8554 29.565 77.8779 27.36C77.9004 25.6275 78.8904 24.5475 80.1504 23.4675H78.8004ZM79.9254 28.755H88.4529L88.2954 25.92C88.2054 24.255 87.4629 23.4675 85.8429 23.4675H80.3979C79.0029 24.66 79.0479 26.145 79.9254 28.755Z" fill="#1F2B70"/>
                            <path d="M75.749 20.6325C75.9515 21.7575 75.929 24.1425 74.399 27.2925L74.219 27.2025C75.2315 25.0425 75.1865 23.4675 73.769 23.4675H71.519C70.934 23.4675 70.3265 23.1975 70.3265 22.365V19.575C70.3265 19.1025 70.709 18.8325 70.979 18.8325C71.0465 19.35 71.384 19.575 71.9465 19.575H74.2865C75.1865 19.575 75.614 19.9125 75.749 20.6325Z" fill="#1F2B70"/>
                            <path d="M68.2405 24.165C68.2855 23.7825 68.218 23.4675 67.813 23.4675H64.7755C64.213 23.4675 63.5605 23.1975 63.5605 22.365V19.575C63.5605 19.1025 63.988 18.8325 64.258 18.8325C64.2805 19.35 64.708 19.575 65.1805 19.575H67.5205C68.5555 19.575 68.713 19.9125 68.668 21.06L68.2855 25.5825C68.1505 27.0675 69.343 29.0925 69.163 30.9375C69.118 31.7025 68.938 32.7375 67.408 32.7375L68.2405 24.165Z" fill="#1F2B70"/>
                            <path d="M58.985 19.575C60.92 19.575 61.91 20.79 61.91 22.59V28.89L61.595 32.6475H51.5375V27.6075C51.5375 25.875 53.045 24.3 53.765 23.4675H52.1675C51.65 23.4675 50.9975 23.1975 50.9975 22.365V19.575C50.9975 19.1025 51.4025 18.81 51.65 18.8325C51.695 19.35 52.1225 19.575 52.595 19.575H58.985ZM53.2025 28.755H61.7075L61.595 25.8525C61.5275 24.1875 60.83 23.4675 59.21 23.4675H53.99C53.27 24.2325 53.2025 24.5025 53.2025 25.5375V28.755Z" fill="#1F2B70"/>
                            <path d="M35.6514 23.4675C35.0664 23.4675 34.4139 23.1975 34.4139 22.365V19.575C34.4139 19.1025 34.8414 18.8325 35.1339 18.8325C35.1339 19.3725 35.6064 19.575 36.0789 19.575H43.6614C44.5839 19.575 44.7414 20.0025 44.7414 20.7V23.4675H43.3464V25.8525C43.3464 27.36 44.7864 29.0925 44.7864 30.9375C44.7864 31.7025 44.2239 32.715 43.1664 32.7375V23.4675H35.6514ZM34.5039 32.7375V29.5425C34.5039 27.72 35.1339 26.64 35.6964 26.2575C35.9664 26.0775 36.2364 26.0775 36.3714 26.1225C36.1914 26.4825 35.9664 27.2025 35.9664 27.8775C35.9664 28.89 36.2589 29.97 36.2589 31.3875C36.2589 32.2875 35.7639 32.715 34.5039 32.7375Z" fill="#1F2B70"/>
                            <path d="M24.8145 23.4675C24.297 23.4675 23.6445 23.1975 23.6445 22.365V19.575C23.6445 19.1025 24.0045 18.8325 24.297 18.8325C24.342 19.2825 24.792 19.575 25.2645 19.575H26.7945C27.717 19.575 27.897 20.0925 27.897 20.5425V23.4675H26.547L22.632 28.755H27.582C29.877 28.755 30.192 28.17 30.9795 26.8425C32.2845 24.6375 32.5545 24.4575 32.5545 23.4675H29.6745C29.157 23.4675 28.5045 23.1975 28.5045 22.365V19.575C28.5045 19.1025 28.8645 18.8325 29.157 18.8325C29.202 19.2825 29.652 19.575 30.1245 19.575H31.0695C32.217 19.575 32.712 20.0475 32.712 21.015V23.58C32.712 25.605 31.002 25.5375 31.002 30.105C31.002 32.1975 29.922 32.6475 28.0095 32.6475H21.147V30.8925C21.147 29.6325 19.572 27.2025 19.572 25.9425C19.572 24.975 20.0445 23.85 21.147 23.4675H19.9095C19.347 23.4675 18.717 23.1975 18.717 22.365V19.575C18.717 19.1025 19.122 18.8325 19.392 18.8325C19.4145 19.2825 19.8645 19.575 20.337 19.575H21.9345C22.812 19.575 23.037 20.0925 23.037 20.5425V23.4675H21.9345C21.372 23.4675 20.1345 23.8275 20.1345 25.1325C20.1345 26.64 22.137 27.4725 22.4295 28.7325L26.322 23.4675H24.8145Z" fill="#1F2B70"/>
                            <path d="M13.6264 18C14.4139 18 14.6389 18.3375 14.7289 18.8775C14.8864 19.845 14.6614 21.96 12.4339 23.1975L12.2764 23.04C12.8164 21.9825 12.9064 20.1825 12.4789 18.1125C12.8614 18.0225 13.2439 18 13.6264 18ZM14.9089 23.04C15.4489 21.9825 15.5389 20.1825 15.1114 18.1125C15.4939 18.0225 15.8764 18 16.2589 18C17.0464 18 17.2714 18.3375 17.3614 18.8775C17.5189 19.845 17.2939 21.96 15.0664 23.1975L14.9089 23.04Z" fill="#1F2B70"/>
                            <path d="M1.215 23.4675C0.6525 23.4675 0 23.1975 0 22.365V19.575C0 19.1025 0.3825 18.8325 0.6975 18.8325C0.72 19.3275 1.17 19.575 1.62 19.575H8.145C10.1025 19.575 11.025 20.835 11.025 22.6575V28.98L9.45 32.6925L1.53 32.6475C1.0575 31.5675 0.27 29.565 0.2925 27.36C0.315 25.6275 1.305 24.5475 2.565 23.4675H1.215ZM2.34 28.755H10.8675L10.71 25.92C10.62 24.255 9.8775 23.4675 8.2575 23.4675H2.8125C1.4175 24.66 1.4625 26.145 2.34 28.755Z" fill="#1F2B70"/>
                            <path d="M115.193 10.8004C113.895 10.8004 112.258 10.1232 112.258 8.03513V5.60816C112.258 4.42306 113.161 3.74586 113.895 3.74586C114.008 4.87452 115.136 5.60816 116.321 5.60816H120.159C122.472 5.60816 122.924 6.90612 122.924 8.03478V10.8004H119.538L109.719 38.8377H122.134C127.89 38.8377 128.68 37.3704 130.655 34.0408C133.928 28.5104 134.605 28.0589 134.605 25.5758H127.382C126.084 25.5758 124.447 24.8986 124.447 22.8106V15.8129C124.447 14.6278 125.35 13.9506 126.084 13.9506C126.197 15.0793 127.325 15.8129 128.51 15.8129H130.881C133.759 15.8129 135 16.998 135 19.4246V25.858C135 30.937 130.711 30.7677 130.711 42.2237C130.711 47.472 128.003 48.6006 123.206 48.6006H105.995V44.1988C105.995 41.0386 102.044 34.9438 102.044 31.7835C102.044 29.3569 103.229 26.5352 105.995 25.5758H102.891C101.48 25.5758 99.9 24.8986 99.9 22.8106V15.8129C99.9 14.6278 100.916 13.9506 101.593 13.9506C101.649 15.0793 102.778 15.8129 103.963 15.8129H107.97C110.17 15.8129 110.735 17.1109 110.735 18.2395V25.5758H107.97C106.559 25.5758 103.455 26.4788 103.455 29.7519C103.455 33.533 108.478 35.621 109.211 38.7813L118.974 10.8004H115.193Z" fill="#1F2B70"/>
                            <path d="M124.313 1.33474C124.08 1.24031 123.824 1.21549 123.576 1.26344C123.329 1.31138 123.102 1.42993 122.924 1.60397L121.275 3.20821L118.351 0.363263C118.112 0.130666 117.788 0 117.45 0C117.112 0 116.788 0.130666 116.549 0.363263L113.625 3.20821L111.976 1.60397C111.798 1.43051 111.571 1.31239 111.324 1.26453C111.076 1.21668 110.82 1.24125 110.587 1.33514C110.354 1.42902 110.155 1.58801 110.015 1.79199C109.875 1.99598 109.8 2.2358 109.8 2.48115V9.04823C109.801 9.87057 110.137 10.6589 110.735 11.2404C111.332 11.8219 112.142 12.149 112.987 12.15H121.912C122.758 12.149 123.568 11.8219 124.165 11.2404C124.763 10.6589 125.099 9.87057 125.1 9.04823V2.48115C125.1 2.23578 125.025 1.9959 124.885 1.79185C124.745 1.58779 124.546 1.42871 124.313 1.33474Z" fill="#1F2B70"/>
                        </svg>
                </div>
                <div className="new-sium-form">
                    <div className="form-headers">
                            <h1>יצירת סיום ש”ס חדש</h1>
                            <h3>מלא את הפרטים וצור סיום ש”ס חדש</h3>
                    </div>
                    <form onSubmit={submitNewSium} className="form-container">
                        <div className="input-row">
                            <div className="input-container">
                                <input
                                    type="text"
                                    value={communityName}
                                    onChange={(e) => setCommunityName(e.target.value)}
                                    className="input"
                                    placeholder="שם הקהילה / הארגון"
                                    name="kehilaName"
                                />
                                {fieldsErrors.communityName && <div className="fields-error-message">{fieldsErrors.communityName}</div>}
                            </div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    value={managerName}
                                    onChange={(e) => setManagerName(e.target.value)}
                                    className="input"
                                    placeholder="שם מנהל סיום הש”ס"
                                    name="name"
                                />
                                {fieldsErrors.managerName && <div className="fields-error-message">{fieldsErrors.managerName}</div>}
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="input-container">
                                <input
                                    type="tel"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    className="input"
                                    placeholder="פלאפון"
                                    name="phone"
                                />
                                {fieldsErrors.phone && <div className="fields-error-message">{fieldsErrors.phone}</div>}
                            </div>
                            <div className="input-container">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="input"
                                    placeholder="אימייל"
                                    name="email"
                                />
                                {fieldsErrors.email && <div className="fields-error-message">{fieldsErrors.email}</div>}
                            </div>
                        </div>
                        <div className="input-container full-width">
                            <input
                                type="text"
                                value={dedication}
                                onChange={(e) => setDedication(e.target.value)}
                                className="input"
                                placeholder="לעילוי נשמת/לרפואת/להצלחת"
                                name="nishmat"
                            />
                        </div>
                        <div className="input-container full-width halukat-shas-container">
                            <label className="halukat-shas-label font-size-20">חלוקת הש"ס לפי</label>
                            <div className="btn-group halukat-shas" role="group" aria-label="How to split the shas">
                                <input type="radio" className="btn-check" value="masehtot" id="halukatMasehtot" checked={haluka === 'masehtot'}
                                       onChange={(e) => setHaluka(e.target.value)}/>
                                <label className="btn btn-outline-primary haluka-button font-size-20" htmlFor="halukatMasehtot">מסכתות / פרקים</label>

                                <input type="radio" className="btn-check" value="dapim" checked={haluka === 'dapim'} id="halukatDapim"
                                       onChange={(e) => setHaluka(e.target.value)}/>
                                <label className="btn btn-outline-primary haluka-button font-size-20" htmlFor="halukatDapim">דפים</label>
                            </div>
                        </div>
                        <div className="input-container full-width halukat-shas-container">
                            <label className="halukat-shas-label font-size-20">בחר האם לכלול מסכתות</label>
                            <div className="btn-group halukat-shas" role="group" aria-label="what masahtot should include">

                                <input type="checkbox" className="btn-check" id="shkalim" autoComplete="off" checked={includeMasehtot.shkalim} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="shkalim">שקלים</label>

                                <input type="checkbox" className="btn-check" id="kanim" autoComplete="off" checked={includeMasehtot.kanim} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="kanim">קנים</label>

                                <input type="checkbox" className="btn-check" id="tamid" autoComplete="off" checked={includeMasehtot.tamid} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="tamid">תמיד</label>

                                <input type="checkbox" className="btn-check" id="midot" autoComplete="off" checked={includeMasehtot.midot} onChange={(e) => handleIcludeMasehtot(e)}/>
                                <label className="btn btn-outline-primary include-masehet-button font-size-20" htmlFor="midot">מדות</label>
                            </div>
                        </div>
                        <div className="input-container full-width halukat-shas-container">
                            <label className="halukat-shas-label font-size-20">האם להוסיף משניות ללא גמרא</label>
                            <div className="btn-group halukat-shas" role="group" aria-label="Sould include Mishna without Gmara">
                                <input type="radio" className="btn-check" value="no" id="withoutMishna" checked={!mishna}
                                       onChange={() => {setMishna(false)}}/>
                                <label className="btn btn-outline-primary haluka-button font-size-20" htmlFor="withoutMishna">לא</label>

                                <input type="radio" className="btn-check" value="yes" checked={mishna} id="withMishna"
                                       onChange={() => {setMishna(true)}}/>
                                <label className="btn btn-outline-primary haluka-button font-size-20" htmlFor="withMishna">כן</label>
                            </div>
                        </div>
                        {haluka === 'masehtot' && <div className="input-row">
                            <div className="input-container">
                                 <label className="sium-dates-picker">תאריך תחילת הלימוד</label><br/>
                                 <DatePicker selectsStart selected={startDate} onChange={date => setStartDate(date)}
                                             startDate={startDate} onFocus={e => e.target.blur()}/>
                                {fieldsErrors.dates && <div className="fields-error-message">{fieldsErrors.dates}</div>}
                            </div>
                            <div className="input-container">
                                 <label className="sium-dates-picker">תאריך סיום הלימוד</label><br/>
                                 <DatePicker selectsEnd selected={endDate} onChange={date => setEndDate(date)} endDate={endDate}
                                             startDate={startDate} minDate={startDate} onFocus={e => e.target.blur()}/>
                            </div>
                        </div>}
                        {!isSubmitting && <div className="input-container full-width">
                            <button type="submit" className="btn new-sium-button dark-button">יצירת סיום הש”ס</button>
                        </div>}
                        {isSubmitting && <div className="input-container full-width">
                            <button type="button" className="btn new-sium-button dark-button">
                                <div className="updateStatusSpinner"></div>
                            </button>
                        </div>}
                    </form>
                </div>
                <div className="sium-bottom-page">
                    <a className="margin-left-10 no-link" target="_blank" rel="noopener noreferrer" href={getTalkWithUsLink()}>צור איתנו קשר</a>
                    <span className="margin-left-10">|</span>
                    <span> © כל הזכויות שמורות לאתר סיום הש"ס</span>
                </div>
            </div>
        )
    } else {
        return (<Spinner/>);
    }
}

export default NewSium;
